<template>
  <div>
    <div v-if="!isEdit">
      <div v-if="type == 'label'">
        <!-- <div v-if="isArray(presentValue)">
          <p v-for="(present, ind) in presentValue" :key="ind" :style="cusStyle">
            {{ present }}
          </p>
        </div> -->
        <!-- <label v-else :style="cusStyle"> {{ presentValue }}</label> -->
        <CustomEditor v-model="applyValue" :style="cusStyle" />
      </div>
      <div v-else-if="type == 'a'">
        <a :href="presentValue" :style="cusStyle" target="_blank">
          {{ presentValue }}
        </a>
      </div>
      <div v-else-if="type == 'email'">
        {{ emailTypePresentValue ? emailTypePresentValue.label : '' }}
        <a style="padding-left: 3px" :href="'mailto:'+emailTypePresentValue.email" :style="cusStyle" target="_blank">
          {{ emailTypePresentValue ? emailTypePresentValue.email : '' }}
        </a>
      </div>
    </div>
    <CustomEditor 
      v-if="isEdit && type !== 'email' && type !== 'a'"
      v-model="applyValue"
      type="textarea"
      class-name="form-control"
      main-class-name="w-100"
      placeholder="Add description"
      :maxlength="isAfterSalesEmail ? getAfterSalesEmailMaxLength() : getMaxLength()"
      :disabled="isAfterSalesEmail ? getAfterSalesEmailMaxLength() === 0 : getMaxLength() === 0"
    />
    <div v-else-if="isEdit">
      <textarea-autosize
        v-model="applyValue"
        class="form-control"
        placeholder="Add description"
        @focusout.native="focusOut()"
      />
    </div>
  </div>
</template>

<script>
import { isArray } from '../../common/CommonUtils';
import CustomEditor from '../../components/custom/CustomEditor.vue';
export default {
  name: "Editor",
  components:{
    CustomEditor
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "label",
    },
    cusStyle: {
      type: String,
      default: "color: black;word-break: break-word",
    },
    numIndex: {
      type: Number,
      default: 0,
    },
    numIdx: {
      type: Number,
      default: 0,
    },
    isAfterSalesEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutateValue: this.value,
      presentValue: /\r|\n/.test(this.value)? this.value.split('\n') : this.value
    }
  },
  computed: {
    applyValue: {
      get: function () {
        return this.mutateValue;
      },
      set: function (newValue) {
        this.mutateValue = this.type == 'a' ? newValue.trim() : newValue;
        this.presentValue =  /\r|\n/.test(newValue)? newValue.split('\n') : newValue;
      },
    },
    emailTypePresentValue() {
      if (this.type === 'email') {
        const [label, email] = this.presentValue.split(' ')
        return {label, email}
      }

      return null
    }
  },
  mounted() {},
  updated() {
    this.$emit("input", this.applyValue);
  },
  methods: {
    focusOut() {
      this.$emit("input", this.applyValue);
    },
    handleInput() {
      this.$emit("input", this.applyValue);
    },
    isArray(data){
      return isArray(data)
    },
    getMaxLength() {
      let maxlength = 1;
      switch (this.numIdx) {
        // Call-to-action
        case 0:
          if(this.numIndex === 0) maxlength = 50;
          if(this.numIndex === 1) maxlength = 180;
          // Find-a-Store button
          if(this.numIndex === 2) maxlength = 20;
        break;
        // Find-a-Store button
        case 1:
          maxlength = 135;
        break;
        case 2:
          // Recommendation Header
          if(this.numIndex === 0) maxlength = 30;
          // Description
          if(this.numIndex === 1) maxlength = 145;
        break;
        // Social Share
        case 3:
          maxlength = 0;
        break;
        // Disclaimer
        case 4:
          maxlength = 350;
        break;
        // Copyright/datestamp
        case 5:
          maxlength = 29;
        break;
        // Unsubscribe Page
        case 6:
          if(this.numIndex === 0) maxlength = 21;
          if(this.numIndex === 1) maxlength = 30;
          if(this.numIndex === 2) maxlength = 55;
        break;
        case 7:
          maxlength = 70;
        break;
      }
      return maxlength;
    },
    getAfterSalesEmailMaxLength() {
      let maxlength = 1;
      switch (this.numIdx) {
        // Header
        case 0:
          maxlength = 180;
          break;
          case 1:
          // Sub-heading
          if(this.numIndex === 0) maxlength = 15;
          // Description
          if(this.numIndex === 1) maxlength = 90;
          break;
        // Links
        case 2:
          if(this.numIndex === 0) maxlength = 15;
          if(this.numIndex === 1) maxlength = 25;
          break;
        // Email title
        case 3:
          maxlength = 80;
          break;
      }
      return maxlength;
    }
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  background-color: transparent;
  color: #321fdb !important;
}
textarea {
  resize: none;
}

.main {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 100%;
}

.main textarea {
  border: none!important;
  margin-bottom: 20px;
}

.main textarea:focus, .main textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.main span {
  color: #768192;
  position: absolute;
  bottom: .375rem;
  right: .75rem;
  font-size: 10px;
}

.icon-img {
  margin: 0 auto;
}

.icon-img > img {
  padding: 10px 0px 10px 10px;
  width: 30px;
  height: 40px
}
</style>
