<template>
  <div>
    <div v-if="!isEdit">
      <img :id="`popover-image-image-0`" :src="applyValue" width="30" height="40" style="padding: 10px 0px 10px 10px;" />
      <ImagePopover
        :product-type="'image'"
        :current-name="'image'"
        :current-image="applyValue"
      />
    </div>
    <div v-if="isEdit">
      <div class="icon-img">
        <img
          :id="`popover-image-image-0`"
          ref="image"
          :src="applyValue"
          @click="chooseImage()"
        />
        <img src="@/assets/icons/replace.png" @click="chooseImage()" />
        <ImagePopover
          v-if="!((value||'')+'').includes('image.png')"
          :product-type="'image'"
          :current-name="'image'"
          :current-image="applyValue"
        />
        <input
          v-show="false"
          ref="uploadImage"
          accept="image/*"
          type="file"
          @change="uploadFile()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Constants from "../../common/Constants";
import ImagePopover from "../common/ImagePopover.vue";

export default {
  name: "FileUpload",
  components: {
    ImagePopover,
  },
  props: {
    value: {
      type: [String, File],
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutateValue: /image\.png$/i.test(this.value)
        ? require(`@/assets/icons/image.png`)
        : this.value instanceof File
        ? URL.createObjectURL(this.value)
        : this.value,
    };
  },
  computed: {
    applyValue: {
      get: function() {
           console.log('this.mutateValue', this.mutateValue)
        return this.mutateValue;
      },
      set: function(newValue) {
        this.mutateValue = newValue;
      },
    },
  },
  mounted() {},
  methods: {
    chooseImage() {
      this.$refs.uploadImage.click();
    },
    uploadFile() {
      const [file] = this.$refs.uploadImage.files;
      const input = this.$refs.uploadImage;
      if (file) {
        const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        if (!validImageTypes.includes(file["type"])) {
          alert("File is not image file.");
          input.value = null;
          return;
        }
        if(file.size > Constants.EMAIL_MAX_SIZE_UPLOAD) {
          alert("File is not larger 1MB.");
          input.value = null;
          return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var image = new Image();
          image.src = reader.result;
          image.onload = () => {
            if(image.width > Constants.EMAIL_MAX_WIDTH_UPLOAD || image.height > Constants.EMAIL_MAX_HEIGHT_UPLOAD) {
              alert("The maximum dimension is 1002x900, please check your image again");
              input.value = null;
              return;
            }
            this.$refs.image.src = reader.result;
            this.$emit("input", file);
            this.applyValue = this.$refs.image.src;    
          }
        };
        reader.onerror = function (error) {
          input.value = null;
          console.log(error);
        };
      }
    },
  },
};
</script>
<style scoped>
.icon-img {
  margin: 0 auto;
}

.icon-img > img {
  padding: 10px 0px 10px 10px;
  width: 30px;
  height: 40px;
}
</style>
